import React from 'react';

interface LicenseProps {
  title: string;
  number: string;
  iconColor: string;
}

const License: React.FC<LicenseProps> = ({ title, number, iconColor }) => {
  return (
    <div className="flex items-center p-2">
      {/* only if width is bigger than 480px */}
      <div className="block">
        <svg
          className={`w-2 h-2 sm:w-6 sm:h-6 ${iconColor} mr-0 sm:mr-4`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          {/* Replace with an appropriate icon */}
          <path d="M10 2a8 8 0 100 16 8 8 0 000-16zm1 11H9v2h2v-2zm0-8H9v6h2V5z" />
        </svg>
      </div>
      <div>
        <p className="text-sm md:text-lg font-semibold text-gray-700">
          {title}
        </p>
        <p className="text-gray-600">License:</p>
        <p className="text-gray-600">{number}</p>
      </div>
    </div>
  );
};

export default License;
