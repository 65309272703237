import React from 'react';
import ContactCard from '../components/ContactCard';


export default function Contact() {
  

  return (
    <div className='pt-24'>
        <ContactCard />
    </div>
  );
}
