import React from 'react';
import { motion } from 'framer-motion';

export default function WIP() {
  return (
    <div className="bg-background h-screen w-full flex flex-col gap-12 justify-center items-center">
      <p className="max-w-[80%] lg:max-w-[30%] text-center font-semibold text-2xl">
        We a currently in the process of migrating our website to this new
        platform. Please check back later. Thank you!
      </p>
      <motion.a
        href="/"
        className="bg-primary p-4 rounded-lg text-white font-semibold"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        Take me home
      </motion.a>
    </div>
  );
}
