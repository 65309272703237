import React from 'react';
import ContentImage from '../components/ContentImage';

export default function Careers() {
  return (
    <div>
      <ContentImage />
    </div>
  );
}
