import React from 'react';
import { motion } from 'framer-motion';
import ContactCard from '../components/ContactCard';

export default function PrivacyPolicy() {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <motion.h1
        className="text-4xl font-bold mb-4 text-gray-800"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        Privacy Policy for Abated LLC
      </motion.h1>
      <motion.p
        className="text-gray-600 mb-8 font-semibold"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
      >
        Effective Date: 01 January 2022
      </motion.p>

      <motion.section
        className="mb-12 bg-white p-6 rounded-lg shadow-md"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Introduction</h2>
        <p className="text-gray-600">
          At Abated LLC, we are committed to protecting the privacy of our clients. This
          privacy policy outlines how we collect, use, and safeguard your personal information when
          you visit our website and use our online contact form.
        </p>
      </motion.section>

      <motion.section
        className="mb-12 bg-white p-6 rounded-lg shadow-md"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Information Collection</h2>
        <p className="text-gray-600">
          When you fill out our online contact form, we collect your name, email address, phone
          number, and the message you provide. This information is collected solely for the purpose
          of responding to your inquiry and providing you with the requested services.
        </p>
      </motion.section>

      <motion.section
        className="mb-12 bg-white p-6 rounded-lg shadow-md"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Information Use</h2>
        <p className="text-gray-600">
          The personal information we collect is used to communicate with you regarding our
          asbestos/lead abatement, asbestos/lead inspection, demolition services, and general
          contracting services. We may use your email address to send you a response to your inquiry
          or to provide you with additional information related to your request.
        </p>
      </motion.section>

      {/* Rest of the privacy policy sections */}

      <motion.section
        className="mb-12 bg-white px-6 rounded-lg shadow-md"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <ContactCard />
      </motion.section>

      <motion.p
        className="text-gray-600"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.6, ease: 'easeOut' }}
      >
        By using our website and submitting your personal information through our online contact
        form, you consent to the collection and use of your personal information as described in
        this privacy policy.
      </motion.p>
    </div>
  );
}