import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState } from 'react';
import License from './License';

export default function GalleryMultiStatic() {
  const animationVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const AnimatedMedia = ({ src, delay }: { src: string; delay: number }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
    const [loaded, setLoaded] = useState(false);

    // Determine if the source is a .webm video
    const isVideo = src.endsWith('.webm');

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={animationVariants}
        transition={{ duration: 0.5, delay }}
        className="w-full h-full rounded-lg overflow-hidden"
      >
        {!loaded && (
          // Skeleton placeholder
          <div className="animate-pulse bg-gray-300 w-full h-full"></div>
        )}
        {isVideo ? (
          <video
            src={src}
            className={`w-full h-full object-cover rounded-lg ${
              loaded ? 'block' : 'hidden'
            }`}
            autoPlay
            loop
            muted
            onLoadedData={() => setLoaded(true)}
          />
        ) : (
          <img
            alt="gallery"
            className={`w-full object-cover h-full object-center block rounded-lg ${
              loaded ? 'block' : 'hidden'
            }`}
            src={src}
            onLoad={() => setLoaded(true)}
          />
        )}
      </motion.div>
    );
  };

  return (
    <section className="">
      <div className="container px-5 py-12 mx-auto flex flex-wrap xl:w-[75%] lg:w-[85%] sm:w-[80%]">
        <div className="flex flex-wrap md:-m-2 -m-1">
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-full">
              <AnimatedMedia
                src={`/imgs/work_promo_${
                  Math.floor(Math.random() * 10) + 1
                }.webm`}
                delay={0.6}
              />
            </div>

            <div className="md:p-2 p-1 w-full">
              <License
                title="Asbestos"
                number="#02019"
                iconColor="text-primaryAlt"
              />
              <License
                title="Lead"
                number="#00803A, #00806E"
                iconColor="text-secondary"
              />
              <License
                title="General Contractor"
                number="#13VH12917600"
                iconColor="text-primary"
              />
            </div>
          </div>

          <div className="flex flex-wrap w-1/2 ">
            <div className="md:p-2 p-1 w-full">
              <AnimatedMedia src={'/imgs/abated-lic.webp'} delay={0.6} />
            </div>
            <div className="md:p-2 p-1 w-full">
              <AnimatedMedia src={'/imgs/Abated_Trucks.webp'} delay={0.2} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
