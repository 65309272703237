import { useState, useEffect } from 'react';
import NavMenu from './NavMenu';
import MenuItems from './MenuItems';

export default function Navbar() {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [logoOpacity, setLogoOpacity] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      // Trigger the shadow after 10 pixels scrolled down
      setIsScrolled(window.scrollY > 10);

      // Calculate the opacity of the logo based on the scroll position
      const opacity = Math.min(1, window.scrollY / 1200);
      setLogoOpacity(opacity);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <NavMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      <header aria-label="Site Header">
        <div
          className={`fixed w-full px-4 py-6 sm:px-6 lg:px-8 bg-white z-50 ${
            isScrolled ? 'shadow-lg' : ''
          }`}
        >
          <div className="flex h-16 items-center justify-between">
            {/* logo */}
            <div className="md:flex md:items-center md:gap-12">
              <a
                className="block text-primary"
                href="/"
                style={{
                  opacity: window.innerWidth < 1280 ? 1 : logoOpacity,
                  transition: 'opacity 0.3s',
                }}
              >
                <img
                  src={'/imgs/Abated Main Logo ALT.png'}
                  alt="company logo"
                  className={`flex rounded-lg max-w-[15rem]`}
                />
              </a>
            </div>
            {/* desktop navigation */}
            <div className="hidden md:block">
              <nav aria-label="Site Nav">
                <MenuItems />
              </nav>
            </div>
            {/* mobile menu button */}
            <div className="block md:hidden">
              <button
                className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75"
                onClick={() => setMenuIsOpen(!menuIsOpen)}
                type="button"
                title="Menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
