import React from 'react';

interface Props {
  setVariable: Function;
  variableName: string;
}

export default function FormInput(props: Props) {
  return (
    <div className="flex flex-col w-full">
      <label className="text-base font-semibold leading-none w-full">
        {props.variableName}
      </label>
      <input
        tabIndex={0}
        arial-label="name"
        type="name"
        className={`text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-gray-100 border rounded border-gray-200  w-full`}
        placeholder={props.variableName}
        onChange={(e) => props.setVariable(e.target.value)}
        required
      />
    </div>
  );
}
