import { useState, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';

interface Props {
  showModal?: boolean;
  setShowModal?: Function;
}

function ContactForm(props: Props) {
  const [formName, setFormName] = useState<string>('');
  const [formEmail, setFormEmail] = useState<string>('');
  const [formPhone, setFormPhone] = useState<string>('');
  const [formMessage, setFormMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [isActive, setisActive] = useState<boolean>(false);

  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: any) => {
    const templateParams = {
      name: formName,
      phone: formPhone,
      email: formEmail,
      message: formMessage,
    };

    emailjs.send(
      process.env.REACT_APP_SERVICE_ID!,
      process.env.REACT_APP_TEMPLATE_ID!,
      templateParams,
      process.env.REACT_APP_PUBLIC_KEY!
    );

    props.setShowModal && props.setShowModal(false);

    // timer
    setTimeout(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    }, 2000);
  };

  useEffect(() => {
    if (
      formName.length > 0 ||
      formEmail.length > 0 ||
      formPhone.length > 0 ||
      formMessage.length > 0
    ) {
      setisActive(true);
    } else {
      setisActive(false);
    }
  }, [
    formName,
    formEmail,
    formPhone,
    formMessage,
    isSubmitting,
    isSubmitted,
    isActive,
  ]);

  useEffect(() => {
    if (isSubmitting) {
      setTimeout(() => {
        setIsSubmitted(true);
      }, 1000);
    }
  }, [isSubmitting]);

  const debouncedSendEmail = debounce(sendEmail, 1000);

  return (
    <div
      className={`w-full flex flex-col items-center justify-center px-10 py-8 ${
        isActive ? 'bg-slate-300/80' : 'bg-slate-200/90'
      } rounded-lg shadow-lg backdrop-blur-md`}
    >
      {/* form start */}
      {/* header text */}
      <p className="md:text-5xl text-2xl font-bold leading-7 text-center w-full py-4">
        Let’s get that quote
      </p>
      <form
        className={`top-40 rounded-sm py-5 w-full px-6`}
        ref={form}
        onSubmit={(e) => {
          e.preventDefault();
          setIsSubmitting(true);
          debouncedSendEmail(e);
        }}
      >
        {/* * inputs */}
        <div className="flex flex-col gap-3 w-full items-center mt-9">
          {/* name input */}
          <div className="flex flex-col w-full">
            <label className="text-base font-semibold leading-none w-full">
              Name
            </label>
            <input
              tabIndex={0}
              arial-label="name"
              type="name"
              className={`text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200  w-full`}
              placeholder="Name"
              onChange={(e) => setFormName(e.target.value)}
              required
            />
          </div>

          {/* email input */}
          <div className="flex flex-col w-full">
            <label className="text-base font-semibold leading-none">
              Email Address
            </label>
            <input
              tabIndex={0}
              arial-label="email address"
              type="email"
              className={`text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200 `}
              placeholder="Email address"
              onChange={(e) => setFormEmail(e.target.value)}
              required
            />
          </div>

          {/* phone input */}
          <div className="flex flex-col w-full">
            <label className="text-base font-semibold leading-none">
              Phone
            </label>
            <input
              tabIndex={0}
              arial-label="phone number"
              name="phone"
              type="tel"
              className={`text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200 `}
              placeholder="Phone number"
              onChange={(e) => setFormPhone(e.target.value)}
              required
            />
          </div>
        </div>

        {/* message input */}
        <div>
          <div className="w-full flex flex-col mt-8">
            <label className="text-base font-semibold leading-none">
              How can we help you?
            </label>
            <textarea
              tabIndex={0}
              aria-label="leave a message"
              name="message"
              className="h-20 text-base leading- none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200 text-black resize-none"
              defaultValue={''}
              placeholder="Leave a message"
              onChange={(e) => setFormMessage(e.target.value)}
              required
            />
          </div>
        </div>

        {/* buttons */}
        <div className="flex items-center justify-center w-full space-x-3">
          {props.setShowModal && (
            <button className="mt-9 text-base font-semibold leading-none text-red-500 py-4 px-10 rounded-sm hover:bg-indigo-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none">
              CANCEL
            </button>
          )}

          <motion.button
            className={`mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-primary rounded-sm focus:ring-2 focus:ring-offset-2 focus:outline-none shadow ${
              isSubmitted ? 'cursor-default bg-primary-dark' : ''
            }`}
            type="submit"
            disabled={isSubmitted}
            whileTap={{ scale: 0.9 }}
          >
            {isSubmitting ? (
              <motion.span
                className="flex items-center"
                animate={{
                  scale: [1, 1.1, 1],
                  rotate: [0, 0, 360],
                  color: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 0.7)',
                    'rgba(255, 255, 255, 1)',
                  ],
                }}
                transition={{
                  duration: 1,
                  times: [0, 0.5, 1],
                  repeat: Infinity,
                }}
              >
                <svg
                  className="w-5 h-5 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.293 5.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-9 9a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 16.586l4.293-4.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Submitting
              </motion.span>
            ) : (
              'SUBMIT'
            )}
          </motion.button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
