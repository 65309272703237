import React from 'react';

interface Props {
  classNames: string;
  text: string;
  activeColor: string;
  inactiveColor: string;
  active: boolean;
  setActive: Function;
}

export default function ToggableDiv(props: Props) {
  return (
    <div
      className={`${props.classNames} ${
        props.active ? props.activeColor : props.inactiveColor
      }`}
      onClick={() => props.setActive(!props.active)}
    >
      {props.text}
    </div>
  );
}
