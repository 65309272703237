import React from 'react';
import { motion } from 'framer-motion';
import ContactCard from '../components/ContactCard';

export default function TermsConditions() {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <motion.h1
        className="text-4xl font-bold mb-4 text-gray-800"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        Terms and Conditions for Abated LLC
      </motion.h1>
      <motion.p
        className="text-gray-600 mb-8 font-semibold"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
      >
        Effective Date: 01 January 2022
      </motion.p>
      <motion.p
        className="text-gray-600 mb-8"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        Please read these Terms and Conditions carefully before using our website and services. By
        accessing or using our website, you agree to be bound by these Terms and Conditions.
      </motion.p>

      <motion.section
        className="mb-12 bg-white p-6 rounded-lg shadow-md"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Services</h2>
        <p className="text-gray-600">
          Abated LLC provides asbestos/lead abatement, asbestos/lead inspection, demolition
          services, and general contracting services. The information provided on our website is for
          general informational purposes only and should not be considered as professional advice.
        </p>
      </motion.section>

      <motion.section
        className="mb-12 bg-white p-6 rounded-lg shadow-md"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Online Contact Form</h2>
        <p className="text-gray-600">
          By submitting your personal information through our online contact form, you consent to
          Abated LLC contacting you regarding your inquiry. We will use your information only for
          the purpose of responding to your request and providing you with the requested services.
        </p>
      </motion.section>

      {/* Rest of the terms and conditions sections */}

      <motion.section
        className="mb-12 bg-white p-6 rounded-lg shadow-md"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <ContactCard />
      </motion.section>

      <motion.p
        className="text-gray-600"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.6, ease: 'easeOut' }}
      >
        By using our website and services, you acknowledge that you have read, understood, and
        agree to be bound by these Terms and Conditions.
      </motion.p>
    </div>
  );
}