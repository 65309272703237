import { useEffect, useRef } from 'react';
import { useAnimation, useInView } from 'framer-motion';
import Review from './Review';

export default function Reviews() {
  const controls = useAnimation();
  const ref = useRef(null);
  const ref_2 = useRef(null);
  const isInView = useInView(ref, {
    margin: '-20%', // top, right, bottom, left
  });
  const isInView_2 = useInView(ref_2, {
    margin: '0px 0px 0px 0px', // top, right, bottom, left
  });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, isInView]);

  useEffect(() => {
    if (isInView_2) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, isInView_2]);

  return (
    <section ref={ref_2} className="flex justify-center items-center ">
      <div className=" px-4 sm:px-6 xl:mr-0 xl:pl-8 xl:pr-0 py-8">
        <div className="grid grid-cols-1 gap-y-6 xl:grid-cols-3 xl:items-center xl:gap-x-16">
          <div className="max-w-xl text-center sm:text-left sticky">
            <h2 className="text-[3rem] sm:text-[4rem] font-bold tracking-tight pb-6">
              What Our Customers Say
            </h2>
          </div>

          <div className="-mx-6 xl:col-span-2 xl:mx-0 px-4">
            <div className="swiper-container !overflow-hidden">
              <div className="swiper-wrapper" ref={ref}>
                <Review
                  author="Brittany Pereira"
                  review="We used Abated recently during the sale of one of our
                          listings (we're realtors) and couldn't be more
                          pleased. They took good care of our client and didn't
                          overcharge. If any of our clients in the future are in
                          need of asbestos removal we will 100% refer them to
                          Abated. Ask for Ryan!"
                  quote="Couldn't be more pleased!"
                  direction="right"
                />

                <Review
                  author="Cia B"
                  review="I cold-called a few asbestos removal companies to get
                          a quote and Abated was the only one who did not turn
                          me off over the phoneentertained my query with
                          patience and was very professional. I texted over some
                          photos of what needed to be removed and got an
                          estimated quote. When the day came to remove the
                          asbestos, they were in constant communication with me
                          since I was not at the property. When I finally saw
                          the job they did, everything was taken care of as
                          agreed upon and they left the place very clean! I was
                          very happy with them!"
                  quote="I was very happy with them!"
                  direction="left"
                />

                <Review
                  author="Alkaline"
                  review="While doing a demo, I realized there was a floor
                          underneath 2 floors! I quickly called Ryan from abated
                          to come give it a quick look. He came the same day to
                          inspect and told me it was in fact asbestos 9x9 floor
                          tile! He had it removed the very next day EFFICIENTLY!
                          VERY good customer service and very reassuring! He
                          took air samples and everything was removed perfectly
                          without a problem! These guys are great! 100/100
                          RECOMMEND. GREAT PRICES, HONEST PEOPLE, HONEST WORK!"
                  quote="These guys are great!"
                  direction="left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
