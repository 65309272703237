import React from 'react';

interface Props {
  col?: boolean;
}
export default function MenuItems(props: Props) {
  return (
    <ul
      className={`flex ${
        props.col && 'flex-col'
      } items-center gap-6 text-lg font-semibold text-black`}
    >
      <li>
        <a className="transition hover:text-gray-500/75" href="/">
          Home
        </a>
      </li>
      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/about">
          About
        </a>
      </li> */}

      <li>
        <a className="transition hover:text-gray-500/75" href="/careers">
          Careers
        </a>
      </li>

      {/* contact */}
      <li>
        <a className="transition hover:text-gray-500/75" href="/contact">
          Contact
        </a>
      </li>

      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/history">
          History
        </a>
      </li> */}

      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/services">
          Services
        </a>
      </li> */}
      {/* 
      <li>
        <a
          className="transition hover:text-gray-500/75"
          href="https://www.instagram.com/abated_acm/"
        >
          Instagram
        </a>
      </li> */}
      {/* <li>
        <a
          className="transition hover:text-gray-500/75"
          href="/privacypolicy"
        >
          Privacy Policy
        </a>
      </li>
      <li>
        <a
          className="transition hover:text-gray-500/75"
          href="/termsandconditions"
        >
          Terms & Conditions
        </a>
      </li> */}

      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/blog">
          Blog
        </a>
      </li> */}
    </ul>
  );
}
