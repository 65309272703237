import React from 'react';
import { motion, useReducedMotion, Variants, Transition } from 'framer-motion';

// Define the props for the component, including the new svgPosition prop
interface ServiceCardProps {
  title: string;
  imageUrl: string;
  description: string;
  svgPosition?: 'tl' | 'tr' | 'bl' | 'br'; // New prop for SVG position
}

// Framer Motion animation variants for the card
const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 10,
    },
  },
};

// The ServiceCard functional component
const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  imageUrl,
  description,
  svgPosition = 'br', // Default to bottom-right
}) => {
  // Check for reduced motion preference
  const prefersReducedMotion = useReducedMotion();

  // Define the transition object with explicit types
  const circleTransition: Transition = {
    duration: 2,
    repeat: Infinity as number,
    repeatType: 'mirror' as const,
    ease: 'easeInOut' as const,
  };

  // Animation variants for the circles fluctuating in size
  const circle1Variants: Variants = !prefersReducedMotion
    ? {
        animate: {
          r: [20, 30, 20],
          transition: circleTransition,
        },
      }
    : {};

  const circle2Variants: Variants = !prefersReducedMotion
    ? {
        animate: {
          r: [15, 25, 15],
          transition: { ...circleTransition, duration: 3 },
        },
      }
    : {};

  // Determine the positioning classes based on svgPosition prop
  const positionClasses = {
    tl: 'top-0 left-0',
    tr: 'top-0 right-0',
    bl: 'bottom-0 left-0',
    br: 'bottom-0 right-0',
  };

  // Get the appropriate positioning class
  const svgPositionClass = positionClasses[svgPosition];

  // Determine if mirroring is needed
  const shouldMirror = svgPosition === 'tl' || svgPosition === 'bl';

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0 }}
      variants={variants}
      className="bg-white shadow-md rounded-lg p-5 text-center max-w-[20rem] relative overflow-hidden"
    >
      {imageUrl.length > 0 ? (
        <>
          {/* Image Version */}
          <h1 className="text-lg font-bold mb-3">{title}</h1>
          <img
            className="w-full mb-3 aspect-square rounded-lg"
            src={imageUrl}
            alt={title}
          />
          <p className="text-slate-700 text-base text-start font-semibold">
            {description}
          </p>
        </>
      ) : (
        <>
          {/* SVG Background Version */}
          {/* SVG Shapes positioned based on svgPosition prop */}
          <div
            className={`absolute ${svgPositionClass} w-32 h-32 opacity-70 z-0 overflow-hidden ${
              shouldMirror ? 'transform -scale-x-100' : ''
            }`}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* First Circle */}
              <motion.circle
                cx="70"
                cy="70"
                r="20"
                fill="#E11D48"
                fillOpacity="0.7"
                variants={circle1Variants}
                initial={{ r: 20 }}
                animate="animate"
              />
              {/* Second Circle */}
              <motion.circle
                cx="50"
                cy="50"
                r="15"
                fill="#1989CE"
                fillOpacity="0.7"
                variants={circle2Variants}
                initial={{ r: 15 }}
                animate="animate"
              />
            </svg>
          </div>
          {/* Content over the SVG */}
          <div className="relative z-10">
            <h1 className="text-lg font-bold mb-3">{title}</h1>
            <p className="text-slate-700 text-base text-start font-semibold">
              {description}
            </p>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default ServiceCard;
