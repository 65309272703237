import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const BehindTheScenes: React.FC = () => {
  // State to manage the selected image and modal visibility
  const [selectedImg, setSelectedImg] = useState<string | null>(null);

  // Effect hook to toggle body scrolling
  useEffect(() => {
    if (selectedImg) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Enable scrolling
    }

    // Cleanup function to re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [selectedImg]); // Dependency array to re-run the effect when selectedImg changes

  // Animation variants
  const imageVariants = {
    hidden: { scale: 0.95, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        duration: 0.5,
        bounce: 0.4,
      },
    },
  };

  return (
    <section className="flex flex-col w-full justify-center items-center">
      <div className="flex">
        <h1 className="font-bold text-4xl md:text-[3rem] xl:text-[5rem] pt-6 pb-20">
          Behind the Scenes
        </h1>
      </div>

      <div className="flex justify-center items-center max-w-full">
        <div className="flex flex-col justify-center items-center gap-4 w-full">
          <motion.div
            className="flex flex-wrap justify-center items-center gap-4"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {Array.from({ length: 9 }).map((_, index) => (
              <motion.video
                key={index}
                src={`/imgs/work_promo_${index + 1}.webm`}
                className="rounded-lg w-1/4 my-2 cursor-pointer"
                onClick={() =>
                  setSelectedImg(`/imgs/work_promo_${index + 1}.webm`)
                }
                variants={imageVariants}
                autoPlay
                loop
                muted
              />
            ))}
          </motion.div>
        </div>
      </div>

      {/* Modal for displaying selected image */}
      {selectedImg && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={() => setSelectedImg(null)}
        >
          <motion.video
            src={selectedImg}
            className="max-w-3/4 max-h-3/4"
            variants={imageVariants}
            initial="hidden"
            animate="visible"
            autoPlay
            loop
            controls
            muted
          />
        </div>
      )}
    </section>
  );
};

export default BehindTheScenes;
