import React from 'react';
import ContactForm from '../components/ContactForm';

export default function Services() {
  return (
    <div className="flex justify-center items-center">
      <ContactForm />
    </div>
  );
}
