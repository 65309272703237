// ButtonComponent.tsx
import React from 'react';
import { motion } from 'framer-motion';

interface ButtonProps {
  label: string;
  onClick?: () => void;
}

const ButtonComponent: React.FC<ButtonProps> = ({ label, onClick }) => {
  const buttonVariants = {
    hover: { scale: 1.05, boxShadow: '0px 0px 8px rgba(0,0,0,0.3)' },
    tap: { scale: 0.95 },
  };

  return (
    <motion.button
      onClick={onClick}
      className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-primary rounded-sm focus:ring-2 focus:ring-offset-2 focus:outline-none shadow w-full"
      variants={buttonVariants}
      whileHover="hover"
      whileTap="tap"
    >
      {label}
    </motion.button>
  );
};

export default ButtonComponent;
